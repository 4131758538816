import React from 'react'
import PropTypes from "prop-types";
// style
import './card.css'

const Card = ({title, text, borderColor}) => (
  <div className="c-container">
    <div className={`c-sub-container bd-${borderColor}`}>
      {title}
      {text}
    </div>
  </div>
)

Card.propTypes = {
  title: PropTypes.element.isRequired,
  text: PropTypes.element.isRequired,
  borderColor: PropTypes.string.isRequired,
}

export default Card
