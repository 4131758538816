import * as React from "react"
import {Helmet} from "react-helmet";
import {graphql} from "gatsby";
import Img from "gatsby-image";
// components
import HeaderQuery from "../components/header/header";
import BasicBlock from "../components/basicBlock/basicBlock";
import BasicTitle from "../components/basicTitle/basicTitle";
import CardContainer from "../components/cardContainer/cardContainer";
import Card from "../components/card/card";
import Footer from "../components/footer/footer";
// styles
import '../styles/agencePage.css'

const Agence = ({ data, path }) => {
  const {headerPicture, secondBlock, threeBlock} = data;
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="fr" />
        <title>Agence</title>
        <link rel="canonical" href="https://webdevvision.fr/agence/" />
        <meta
          name="description"
          content="Nous sommes une agence de développement Web et Mobile sur Gaillac, passionnée et engagée dans la stratégie digitale, la conception UI / UX et le développement d’applications web et mobile."
        />
      </Helmet>
      <HeaderQuery />
      <BasicBlock
        containerLeft={() => (
          <>
            <h1 className="ag-header">
              Bienvenue chez
              <br/>
              <span className="primary">Web</span>
              <span className="secondary">Dev</span>
              <span className="other">Vision !</span>
            </h1>
            <p className="secondary ag-subTitle">
              Nous concevons, réalisons et maintenons des projets web et mobile.
              Chaque plateforme est conçue sur mesure selon le besoin.
              Notre méthode de travail est toujours la même:
              échanger, analyser et agir pour aboutir à une solution de haute performance.
            </p>
          </>
        )}
        containerRight={() => (
          <>
            <Img fluid={headerPicture.childImageSharp.fluid}  />
          </>
        )}
        containerRightFirstOnMobile
        containerRightContainPicture
        containerLeftContainPicture={false}
        background="tertiary"
      />
      <BasicTitle title="Pourquoi nous choisir ?" colorText="primary" colorSpan="primary" />
      <CardContainer isFullResponsive={false}>
        <Card
          title={
            <h3 className="ag-card-title secondary">
              Découvrir, écouter et définir vos besoins
            </h3>
          }
          text={
            <p className="ag-card-text secondary">
              Dans un premier temps nous sommes à <span className="primary bold">l'écoute</span> de
              vos <span className="primary bold">besoins</span> et grâce à
              notre <span className="primary bold">vision</span> et <span className="primary bold">expertise</span> nous
              trouvons la formule technique qui correspond le mieux à votre problématique.
              Nous devenons pour vous des interlocuteurs agiles, investis et compétents
              pour votre projet. On est <span className="primary bold">ensemble</span> et
              on vise la <span className="primary bold">performance</span>.
            </p>
          }
          borderColor="primary"
        />
        <Card
          title={
            <h3 className="ag-card-title secondary">
              Sens & Cohérence
            </h3>
          }
          text={
            <p className="ag-card-text secondary">
              De la conception à l'exécution nous sommes
              avec <span className="primary bold">vous</span> pour donner le
              maximum de sens et de cohérence à votre projet.
              Pour ce faire, on créé l'architecture graphique au travers
              de <span className="primary bold">wireframes</span> et de <span className="primary bold">prototypes</span>.
              Cela permet d'éliminer les dernières incohérences ayant pu persister.
              Une fois validée, la phase de <span className="primary bold">développement</span> peut commencer.
            </p>
          }
          borderColor="secondary"
        />
        <Card
          title={
            <h3 className="ag-card-title secondary">
              Agilité et développement
            </h3>
          }
          text={
            <p className="ag-card-text secondary">
              Nous utilisons des méthodes <span className="primary bold">agiles</span> qui
              nous permettent d'organiser nos phases de développement
              en <span className="primary bold">sprint</span> de 1 à 2 semaines.
              À chaque fin de sprint, la solution est déployée sur un serveur privé pour que le client puisse
              la <span className="primary bold">tester</span>.
              Un espace lui est dédié, avec l'outil <span className="primary bold">Jira</span>, pour qu'il puisse
              voir en temp réel l'avancée de son projet et faire remonter d'éventuels problèmes.
            </p>
          }
          borderColor="other"
        />
      </CardContainer>
      <BasicTitle title="Notre vision" colorText="other" colorSpan="other" />
      <BasicBlock
        containerLeft={() => (
          <p className="secondary">
            Notre vision ne consiste pas seulement à vendre des solutions Web ou Mobile,
            nous voulons aussi que nos clients soient pleinement <span className="primary bold">satisfaits</span> de nos prestations,
            et qu'ils deviennent à <span className="primary bold">long terme</span> des partenaires d'affaires et de choix.
            Nous sommes passionnés par le domaine du Web et du Mobile et conscients que cela représente la clé du succès.
            <br/><br/>
            Nous nous efforçons constamment de nous améliorer pour faire partie des entreprises les plus performantes du marché.
            Nous voulons qu'un sentiment de <span className="primary bold">confiance</span> s'installe avec le client et nous mettons
            à sa disposition des outils pour faciliter le dialogue qui est primordial pour le bon déroulement d'un projet.
            <br/><br/>
            Nous n'hésitons pas à être force de proposition et à prendre le temps de développer les idées jusqu'au bout.
            Nous nous formons aux dernières technologies hebdomadairement afin
            de proposer des solutions Web ou Mobile de <span className="primary bold">hautes performances</span>.
            De sorte, qu'avec tout cela, on vous livre une expérience hors du commun.
          </p>
        )}
        containerRight={() => (
          <>
            <Img fluid={secondBlock.childImageSharp.fluid}  />
          </>
        )}
        containerRightFirstOnMobile
        containerRightContainPicture
        containerLeftContainPicture={false}
        background="tertiary"
      />
      <BasicTitle colorText="secondary" colorSpan="secondary" title="Nos engagements" />
      <BasicBlock
        containerRight={() => (
          <p className="secondary ag-blockThree-text">
            Nous nous engageons à réaliser votre site dans des délais clairs, fixés au préalable lors des premières réunions.
            Pour se faire la <span className="primary bold">communication</span> est notre mot-clé.
            Nous ferons le nécessaire pour que vous ayez une <span className="primary bold">visibilité</span> complète lors des différentes étapes du projet.
            Nous sommes force de proposition et à l'écoute de vos besoins durant toute la vie de votre site.
            <br/><br/>
            Nous réaliserons votre site web ou votre application mobile dans une optique d'efficacité et de fluidité en mettant l'accent sur la performance et l'UI / UX utilisateur.
            Nous pensons le projet à <span className="primary bold">long terme</span>, pour cela nous créons des applications avec un code épuré, réutilisable et aux normes.
            Cela permet à l'application de pouvoir facilement <span className="primary bold">évoluer</span> dans le futur,
            mais aussi d'avoir une <span className="primary bold">maintenance à bas coût</span>.
          </p>
        )}
        containerLeft={() => (
          <>
            <Img fluid={threeBlock.childImageSharp.fluid}  />
          </>
        )}
        containerRightFirstOnMobile={false}
        containerRightContainPicture={false}
        containerLeftContainPicture={true}
        background="other"
      />
      <Footer path={path} />
    </main>
  )
}

export const query = graphql`
  query {
    headerPicture: file(relativePath: { eq: "agence-blockOne.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    secondBlock: file(relativePath: { eq: "agence-blockTwo.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    threeBlock: file(relativePath: { eq: "agence-blockThree.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Agence
